.menu-dmp-categories {
  padding: 0 0 1rem 0;

  .category-label {
    flex-grow: 1;
    display: flex;
    flex-flow: row;
    align-items: center;

    .label {
      flex-grow: 1;
    }
  }
  .arrow-button {
    &.toggle-menu {
      margin-left: -1.6rem;
      padding-left: 0;
      padding-right: 0.5rem;
    }

    i {
      justify-content: center;
      width: 16px;

      &.ic-chevron-down {
        font-size: 10px;

        :before {
          color: $primary-700;
        }
      }
      &.ic-chevron-right {
        font-size: 14px;

        :before {
          color: $gray-300;
        }
      }
    }
  }

  & .nav > li.nav-dropdown, & .nav.nav-favorites {

    & .has-arrow::after {
      transform: rotate(135deg) translate(0, -50%);
    }

    &.active .has-arrow::after {
      transform: rotate(225deg) translateY(-50%);
    }

    & > div, & > .nav-sub > li .category, & > li .category {
      min-height: calc(2rem + #{$spacer} / 4);
      padding-right: 0.65rem;
      border-radius: $border-radius;
      display: flex;
      flex-flow: row;
      align-items: center;
      border: $border-width solid transparent;

      &.checked {
        border-color: $gray-200;
      }
    }

    & > div, & > .nav-sub > li, & > li {

      margin-bottom: $spacer / 4;

      & span.badge {
        background-color: $gray-100;
        border: $border-width solid $gray-200;
        border-radius: 10px;
        margin-left: $spacer / 3;
        font-weight: 400;

        &.empty {
          background-color: $white;
          border: $border-width solid $gray-200;
          color: $gray-300;
        }

        &.new {
          background-color: $primary-500;
          border: $border-width solid $primary-500;
          color: $white;
          font-weight: 700;
        }
      }
    }
  }

  .nav > li.nav-dropdown .nav-sub, .nav.nav-favorites {
    padding-left: 0;
    li {
      display: flex;
      flex-flow: row;
      align-items: center;
      padding-left: 2rem;
      position: relative;

      .fav-doc-category-button {
        display: none;
        position: absolute;
        left: 0.8rem;

        i {
          color: #F7C96E;
        }
      }

      &:hover .fav-doc-category-button, .fav-doc-category-button.fav {
        display: block;
      }

      .category {
        flex-grow: 1;
        padding-left: 0.65rem;
      }
    }
  }

  .nav.nav-favorites {
    li {
      margin-left: -2rem !important;
    }
  }

  .nav.nav-favorites li:not(:hover) .fav-doc-category-button.fav {
    display: none;
  }
}
