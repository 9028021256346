/*=========================================================================
Base
========================================================================== */
.modal {
  &.show {
    display: block !important;
  }
  .modal-header {
    border-bottom: none;
    &.bg-sand {
      background-color: $sand-100;
      border-bottom: 1px solid $sand-300;
    }
  }
  .modal-footer {
    background-color: $sand-100;
    border-bottom: 1px solid $sand-300;
    padding: 0.75rem 1rem;
  }
  button.close {
    color: $body-color;
    text-shadow: none;

    &:active,
    &:focus {
      outline: 0;
    }
  }

  &[data-modal="scroll"] {
    .modal-body {
      max-height: 450px;
      overflow-y: auto;
    }
  }
  .modal-content{
    border: 0;
  }
}

.modal-xl{
  &.modal-dialog{
    max-width: 1200px;
  }
}
.modal-auto {
  &.modal-dialog{
    width: fit-content;
  }
}

.tooltip-inner {
  max-width: none;
}
