.btn {
  transition: all .2s ease;
  text-align: left;
}

.btn-xs {
  padding: 0.3rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
  height: 32px !important;

  i {
    font-size: .75rem;
  }
}

.btn-unstyled{
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.btn-sand{
  background-color: $white;
}

@each $color, $value in $theme-colors {
  .btn-borderless-#{$color}{
    color: map-get($btn-borderless-colors , $color);
    border-color:  transparent;
    i{
      &:before{
        color: map-get($btn-borderless-colors , $color);
      }
    }
  }
  .btn-borderless-#{$color}:not(:disabled):not(.disabled):hover {
    box-shadow: none;
    color: map-get($btn-active-borderless-colors, $color);
    background-color:  map-get($btn-active-borderless-background-colors, $color);
    border-color:  transparent;
    i{
      &:before{
        color: map-get($btn-active-borderless-colors, $color);
      }
    }
  }
  .btn-#{$color}{
    color: map-get($btn-colors , $color);
    border-color:  map-get($btn-border-colors, $color);
    i{
      &:before{
        color: map-get($btn-colors , $color);
      }
    }
  }
  .btn-#{$color}:not(:disabled):not(.disabled) {
    &:active, &.active, &:hover {
      box-shadow: none;
      color: map-get($btn-active-colors, $color);
      background-color: map-get($btn-active-background-colors, $color);
      border-color: map-get($btn-active-border-colors, $color);

      i {
        &:before {
          color: map-get($btn-active-colors, $color);
        }
      }
    }
  }
  .btn-outline-#{$color}:not(:disabled):not(.disabled) {
    border-color:map-get($btn-outline-border-colors, $color);
    color:map-get($btn-outline-colors, $color);
    background-color:map-get($btn-outline-background-colors, $color);
    i{
      &:before{
        color:map-get($btn-outline-colors, $color);
      }
    }
    &:active,
    &.active,&:hover  {
      background-color:  map-get($btn-active-outline-background-colors, $color) !important;
      border-color: map-get($btn-outline-border-colors, $color);
      color:map-get($btn-active-outline-colors, $color);
      box-shadow:none;
    }
  }
}

.btn.btn-outline-secondary.disabled, .btn.btn-outline-secondary:disabled {
  opacity: .65;
}

.btn-group {
  & > .btn {
    font-weight: 400;
  }
  & > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) {
    border-left-width: 0;
  }
}

.btn-icon {
  border: none;
  color: $secondary;

  & i {
    font-size: 1.25rem;
  }
}

/*.btn-icon-only {
  &.btn {
    padding: 0.4rem;
    margin-right: map-get($spacers, 1);
    margin-top: map-get($spacers, 1);
  }
  & i {
    font-size: 1.5rem;
  }
}*/

@media(max-width: map-get($grid-breakpoints, lg)) {
  .hide-text-md > span {
    display: none;
  }
}

.btn {
  height: 40px;
  &.btn-sm{
    &.btn-with-pill:after {
      content: '';
      position: relative;
      top: -9px;
      right: -9px;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background-color: #F28118;
      color: #000000;
      font-size: 14px;
      z-index: 100;
    }
    height: 32px
  }
  &.btn-lg{
    height: 49px
  }
  display: inline-flex;
  justify-content: center;
  align-items: center;
  --padding-x: 1.2em;
  &.btn-secondary:hover{
    &:hover{
      border: 1px solid #BFD4F2;
    }
    border: 1px solid #DEE5EF;
  }
  &.btn-primary{
    border: none;
  }
  i{
    margin-right: 0.5rem;
  }
  &.btn-icon-only{
    i{
      margin-right: 0rem !important;
    }
  }
}

.btn-link {
  height: auto;
  font-weight: 500;
  padding-left: 0;
  padding-right: 0;

  & > i:before {
    color: inherit;
  }
}
